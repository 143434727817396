import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const FollowMeImage = () => (
  <StaticQuery
    query={graphql`
      query FollowMeImageQuery {
        followMeImage: file(relativePath: { eq: "FollowMe.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        fluid={data.followMeImage.childImageSharp.fluid}
        style={{ maxHeight: '1000px' }}
        imgStyle={{ maxHeight: '1000px', objectFit: 'contain' }}
      />
    )}
  />
);

export default FollowMeImage;
