import React, { Component } from 'react';
import { Container, Breadcrumb, BreadcrumbItem, Button } from 'reactstrap';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout';
// import styles from '../css/aboutus.module.css';
import PageHeading from '../../components/PageHeading/PageHeading';
import TitleBar from '../../components/TitleBar/TitleBar';
import FollowMeImage from '../../components/GatsbyImageComponents/FollowMeImage';

class Donate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Layout>
        <Helmet
          title="VEYM | Donate"
          meta={[
            {
              name: 'description',
              content: `VEYM greatly appreciates your support!`,
            },
          ]}
        />
        <PageHeading>Donate</PageHeading>

        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Donate</BreadcrumbItem>
          </Breadcrumb>
        </Container>

        <br />

        <TitleBar>Support VEYM</TitleBar>
        <br />
        <Container>
          <p>Click the button below to donate. Thank you for your support!</p>
          <form
            action="https://www.paypal.com/cgi-bin/webscr"
            method="post"
            target="_blank"
          >
            <input type="hidden" name="cmd" value="_s-xclick" />
            <input
              type="hidden"
              name="hosted_button_id"
              value="XHVUSB9W79KMU"
            />
            <Button size="lg" color="secondary">
              DONATE
            </Button>
          </form>
          <br />
          <br />
          <br />
          <FollowMeImage />
        </Container>
        <br />
        <br />
        <br />
      </Layout>
    );
  }
}

export default Donate;

export const frontmatter = {
  title: 'Donate',
  category: 'pages',
  path: '/stewardship/donate',
  description: 'Donate',
  tags: 'donate stewardship ',
};
